<template>
  <div>
    <h1>
      ACTIONS
    </h1>
    <cbs-objects-tbl prop-filter="actions" />
    <div>
      <p>session: {{ session }}</p>
      <p>object: {{ object }}</p>
    </div>
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  components: {
    CbsObjectsTbl,
  },
  data() {
    return {
      session: null,
      object: null,
    }
  },
  created() {
    this.whoami()
    this.loadObjectBySid()
    useCubus.setPageTitle('Actions')
  },
  methods: {
    whoami() {
      useCubus.loadWhoamiVm(this)
    },
    loadObjectBySid() {
      useCubus.loadObjectBySid(this, {
        objectsid: 'mount',
        domainsid: '',
      })
    },
  },
}
</script>

<style scoped>

</style>
